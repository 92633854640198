<template>
    <div>
        <div class="pageheader pd-t-25 pd-b-15">
            <div class="pd-t-5 pd-b-5">
                <h1 class="pd-0 mg-0 tx-20 text-overflow">Data Pengguna</h1>
            </div>
            <div class="breadcrumb pd-0 mg-0">
                <a class="breadcrumb-item" href="#"><i class="fa fa-users mg-r-5"></i>Data Master</a>
                <span class="breadcrumb-item active"> Data Pengguna</span>
            </div>
        </div> 
        <!-- MODALS ================================================== -->
        <modal name="my-modal" height="auto" :scrollable="true">
            <div class="modal-content">
                <div class="modal-card">
                    <div class="card-header">
                        <!-- Title -->
                        <h5 class="card-header-title">
                            Form Pengguna
                        </h5>
                    </div>
                    <form @submit.prevent="submitForm" enctype="multipart/form-data">
                        <div class="card-body">
                            <div class="form-group">
                                <div class="row align-items-center">
                                    <input v-model="form.role" type="hidden">
                                    <label class="col-5">Username<span class="text-danger">*</span></label>
                                    <label class="col-7">Nama Lengkap<span class="text-danger">*</span></label>
                                    <div class="col-5">
                                        <input v-if="form.role != 'administrator'" type="text" v-model="form.username" value="" class="form-control" placeholder="" required="">
                                        <input v-if="form.role == 'administrator'" type="number"  value="" disabled class="form-control" placeholder="admin" required="">
                                    </div>
                                    <div class="col-7">
                                        <input type="text" v-model="form.name" value="" class="form-control" placeholder="" required="">
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="form-group">
                                <div class="row align-items-center">
                                    <label class="col-6">Kata Sandi</label>
                                    <label class="col-6">Ulang Kata Sandi</label>
                                    <div class="col-6">
                                        <input type="password" v-model="form.password" value="" class="form-control" placeholder="">
                                        <span>&nbsp;</span>
                                    </div>
                                    <div class="col-6">
                                        <input type="password" @keyup="matchPassword()" v-model="form.password_confirm" value="" class="form-control" placeholder="" >
                                        <span v-if="checkPassword == 0">&nbsp;</span>
                                        <span v-if="checkPassword == 2"><a class="text-danger">Password Tidak Sesuai</a></span>
                                        <span v-if="checkPassword == 1"><a class="text-success">Password Sesuai</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer border-top-0">
                            <ul class="pagination mb-0"></ul>
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-text text-muted" @click="hide_modal()">Batal</button>
                                <button type="submit" class="btn btn-primary">Simpan</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </modal>
        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                <!-- CONTENT -->
                <div class="tab-content mb-3">
                    <div class="tab-pane show active" id="anggota-tab" role="tabpanel">
                        <div class="card">
                            <div class="card-header">
                                <div class="input-group input-group-flush input-group-merge">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="fe fe-users mr-2"></span>{{ totalPengguna }} Pengguna
                                        </div>
                                    </div>
                                    <input type="search" class="form-control form-control-prepended search" v-model="keywords" v-on:keyup.enter="getData(0)" placeholder="Cari Pengguna...">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="fe fe-search"></span>
                                        </div>
                                    </div>
                                    <!-- HEADER -->
                                    <button class="btn btn-primary ml-2"  @click="show_modal()">
                                        <div class="d-flex align-items-center">
                                            <i class="fe fe-plus mr-2"></i>Tambah Pengguna
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="m-2 table table-sm table-striped  table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Username</th>
                                            <th>Nama</th>
                                            <th v-if="checkDomain == 'persediaan.e-planningbmd.id'">BA Stockopname</th>
                                            <th>Role</th>
                                            <th style="width:5%">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="fetching">
                                        <tr>
                                            <td colspan="5">
                                                <div class="alert alert-primary" role="alert">
                                                    Sedang mengambil data..
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="!fetching">
                                        <tr v-for="(user, index) in dataPengguna" :key="index">
                                            <td>{{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}</td>
                                            <td>{{ user.username }}</td>
                                            <td>{{ user.name }}</td>
                                            <td v-if="checkDomain != 'persediaan.e-planningbmd.id'">
                                                <!-- <a v-if="user.sp" target="_blank" :href="'https://persediaanbmd.bandungkab.go.id/api/dokumen/' + user.sp" class="btn btn-outline-info btn-sm mr-1"><i class="fe fe-file mr-1"></i>SP</a> -->
                                                {{ user.tgl_ba }}<a v-if="user.ba" target="_blank" :href="'https://persediaanbmd.bandungkab.go.id/api/dokumen/' + user.ba" class="ml-2 mr-1"><i class="text-primary fe fe-file mr-1"></i></a>
                                                <!-- <button v-if="user.ba && user.phone == 9" @click.prevent="postReject(user.id)" class="btn btn-success btn-sm mr-1"><i class="fa fa-check-square-o mr-1"></i>BA Tervalidasi</button>
                                                <button v-if="user.ba && user.phone == 2" @click.prevent="postValidate(user.id)" class="btn btn-success btn-sm mr-1"><i class="fa fa-check-square-o mr-1"></i>Validasi BA</button>
                                                <button v-if="user.ba && user.phone == 10" @click.prevent="postMessage(user.id)" class="btn btn-info btn-sm mr-1"><i class="fa fa-paper-plane mr-1"></i>Kirim Notifikasi</button>
                                                <button v-if="user.ba && user.phone == 11" disabled class="disabled btn btn-info btn-sm mr-1"><i class="fa fa-check-square-o mr-1"></i>Notifikasi terkirim</button> -->
                                                
                                            </td>
                                            <td>
                                            <span v-if="user.role == 'administrator'" style="width:80px" class="mt-2 badge badge-warning mr-3">Administrator</span>
                                            <span v-if="user.role == 'dinas'" style="width:80px" class="badge badge-secondary mr-3">Dinas</span>
                                            </td>
                                            <td class="text-center">
                                                <a v-if="user.role == 'dinas'" class="btn btn-outline-info btn-sm mr-2">
                                                    <router-link :to="{ name:'master.pengguna_detail',params: { id: user.id }}">
                                                    <i class="fe fe-users"></i>
                                                    </router-link>
                                                </a>
                                                <button type="button" v-if="user.role == 'administrator'" disabled class="btn btn-secondary btn-sm mr-2"><i class="fe fe-users"></i></button>
                                                    
                                                <button @click.prevent="editForm(user.id)" class="btn btn-warning btn-sm mr-2"><i class="fe fe-edit"></i></button>
                                                <button v-if="user.role == 'dinas'" @click.prevent="postDelete(user.id)" class="btn btn-danger btn-sm mr-2"><i class="fe fe-trash-2"></i></button>
                                                <button v-if="user.role == 'administrator'" disabled class="btn btn-danger btn-sm mr-2"><i class="fe fe-trash-2"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer border-top-0">
                                <div style="float:right">
                                    <!-- <ul class="pagination mb-0"></ul> -->
                                    <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage"
                                                :page-count="pagination.totalPages"
                                                :classes="pagination.bootstrapPaginationClasses"
                                                :labels="pagination.paginationAnchorTexts"
                                                v-on:change="getData(0)"></v-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    export default {
        metaInfo: {
            title: 'SI',
            titleTemplate: '%s - Data Pengguna'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                dataPengguna: [],
                dataPengutamaan: [],
                totalPengguna: 0,
                fetching: true,
                checkPassword: 0,
                checkDomain: '',
                form: {
                    id: '',
                    username: '',
                    name: '',
                    phone: '',
                    email: '',
                    pengutamaan: '',
                    role: '',
                    password: '',
                    password_confirm: '',
                },
                pagination: {         
                    currentPage: 1,
                    totalPages: 0,
                    limit: 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'  
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
                keywords: '',
                showSelectYear: true
                
            }
        },
        created() {
            this.getData(0);
            this.check_domain();
        },
        components: {
            vPagination
        },
        methods: {
            check_domain() {
                this.checkDomain = window.location.hostname;
            },
            show_modal() {
                this.form.id = '';
                this.form.username = '';
                this.form.name = '';
                this.form.phone = '';
                this.form.email = '';
                this.form.pengutamaan = '';
                this.form.password = '';
                this.form.password_confirm = '';
                this.form.role = 'siswa';
                this.$modal.show('my-modal');
            },
            hide_modal() {
                this.$modal.hide('my-modal')
            },
            matchPassword() {
                if (this.form.password == this.form.password_confirm && this.form.password_confirm != '') {
                    this.checkPassword = 1;
                } else {
                    this.checkPassword = 2;
                }
            },
            // Get data data periode
            getData(key) {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/master/pengguna', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                        key: key,
                    }
                })
                    .then((response) => {
                        this.dataPengguna  = response.data.data;
                        this.totalPengguna = response.data.total;
                        this.fetching = false;
                        this.pagination.totalPages = response.data.total_page;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            // save periode
            submitForm() {
                this.$http.post(this.baseUrl + 'admin/master/pengguna/save', this.form)
                    .then(() => {
                        this.$swal({
                            icon: 'success',
                            title: "success",
                            text: 'Data Pengguna berhasil disimpan',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.$modal.hide('my-modal')
                        this.getData(0)
                        this.form.nama_kegiatan = ''
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    });
            },
            // Edit data periode
            editForm(id) {
                this.$http.get(this.baseUrl + `admin/master/pengguna/detail?id=${id}`)
                    .then((response) => {
                        this.form.id = response.data.data[0].id;
                        this.form.nim = response.data.data[0].nim;
                        this.form.name = response.data.data[0].name;
                        this.form.phone = response.data.data[0].phone;
                        this.form.email = response.data.data[0].email;
                        this.form.role = response.data.data[0].role;
                        this.form.password = '';
                        this.form.password_confirm = '';
                        if(response.data.data[0].id_pengutamaan == null){
                        this.form.pengutamaan = '';
                        }else{
                        this.form.pengutamaan = response.data.data[0].id_pengutamaan;
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                this.$modal.show('my-modal');
            },
            // Delete data periode
            postDelete(id) {
                var fd = new FormData()
                fd.append('id', id)
                this.$swal({
                    title: 'Apakah kamu yakin?',
                    html: 'Data yang dihapus tidak dapat di kembalikan',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.$http({
                            url: this.baseUrl + 'admin/master/pengguna/delete',
                            method: 'post',
                            data: fd
                        }).then((response) => {
                            if (response.data.status_pemakaian != 'digunakan') {
                                this.$swal(
                                    'Deleted!',
                                    'Data berhasil dihapus.',
                                    'success'
                                )
                            } else {
                                this.$swal(
                                    'Tidak dapat di hapus!',
                                    'Periode sudah digunakan.',
                                    'info'
                                )
                            }
                            this.getData(0);
                        }).catch(error => {
                            console.log(error.response.data)
                        })
                    }
                });
            },
            postValidate(id) {
                var fd = new FormData()
                fd.append('id', id)
                this.$swal({
                    title: 'Apakah kamu yakin?',
                    html: 'Jika pengguna berhasil di validasi maka akan dilakukan proses migrasi saldo awal 2024 dan pengguna bisa input transaksi di tahun 2024 ',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Validate it!'
                }).then((result) => {
                    if (result.value) {
                        this.$http({
                            url: this.baseUrl + 'admin/master/pengguna/validate',
                            method: 'post',
                            data: fd
                        }).then((response) => {
                            if (response.data.status_pemakaian != 'digunakan') {
                                this.$swal(
                                    'Unvalidated!',
                                    'Data berhasil divalidasi.',
                                    'success'
                                )
                            } else {
                                this.$swal(
                                    'Tidak dapat divalidasi!',
                                    'Periode sudah digunakan.',
                                    'info'
                                )
                            }
                            this.getData(0);
                        }).catch(error => {
                            console.log(error.response.data)
                        })
                    }
                });
            },
            postReject(id) {
                var fd = new FormData()
                fd.append('id', id)
                this.$swal({
                    title: 'Apakah kamu yakin?',
                    html: 'Jika pengguna dibatalkan validasi maka pengguna tidak dapat mengakses transaksi 2024, dan pengguna dapat upload kembali surat pernyataan serta ba stock opname ',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Unvalidate it!'
                }).then((result) => {
                    if (result.value) {
                        this.$http({
                            url: this.baseUrl + 'admin/master/pengguna/reject',
                            method: 'post',
                            data: fd
                        }).then((response) => {
                            if (response.data.status_pemakaian != 'digunakan') {
                                this.$swal(
                                    'Validated!',
                                    'Data berhasil divalidasi.',
                                    'success'
                                )
                            } else {
                                this.$swal(
                                    'Tidak dapat divalidasi!',
                                    'Periode sudah digunakan.',
                                    'info'
                                )
                            }
                            this.getData(0);
                        }).catch(error => {
                            console.log(error.response.data)
                        })
                    }
                });
            },
            postMessage(id) {
                var fd = new FormData()
                fd.append('id', id)
                this.$swal({
                    title: 'Apakah kamu yakin?',
                    html: 'Notifikasi akan dikirim ke grup sebagai pemberitahuan!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Terkirim!'
                }).then((result) => {
                    if (result.value) {
                        this.$http({
                            url: this.baseUrl + 'admin/master/pengguna/send',
                            method: 'post',
                            data: fd
                        }).then((response) => {
                            if (response.data.status) {
                                this.$swal(
                                    'Terkirim!',
                                    'Notifikasi berhasil dikirim.',
                                    'success'
                                )
                            } else {
                                this.$swal(
                                    'Tidak dapat dikirim!',
                                    'Periode sudah digunakan.',
                                    'info'
                                )
                            }
                            this.getData(0);
                        }).catch(error => {
                            console.log(error.response.data)
                        })
                    }
                });
            },
        },
    };
</script>
<style>
    /* style sweetalert */
    .swal2-html-container {
        display: block;
        text-align: center;
    }
</style>